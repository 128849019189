import React from "react";
import { Link } from "gatsby";
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Image,
  
} from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import LuteinComplexMobile from "./../../images/hepato-grigorov-header-mobile.jpg";
import LuteinComplexTablet from "./../../images/hepato-grigorov-header.jpg";
import LuteinComplexDesktop from "./../../images/hepato-grigorov-header.jpg";
import LuteinComplexXL from "./../../images/hepato-grigorov-header.jpg";

import enzimi from "./../../images/hepato-liver-x-ray-covid.jpg";
import hepatofelin from "./../../images/hepatofelin-article-box.jpg";

const HepatofelinGrigorov = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepatofelin-grigorov" }}>
    <Seo title="Имаме решение за увредения от вируси и антибиотици черен дроб" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ИМАМЕ РЕШЕНИЕ ЗА УВРЕДЕНИЯ ЧЕРЕН ДРОБ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Имаме решение за увредения от вируси и антибиотици черен дроб"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Имаме решение за увредения от вируси и антибиотици черен дроб
          </h1>
          <h2 className="font-light">
            <em>Интервю с проф. д-р Никола Григоров – „Пирогов“, София</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Проф. д-р Никола Григоров - едно от най-големите имена в родната
            гастроентерология и хепатология. Дългогодишен експерт и консултант,
            специализирал в редица водещи в медицината държави, създал школа в
            ИСУЛ, а понастоящем и в УМБАЛСМ „Н. И. Пирогов“- Отделение по
            гастроентерология.
          </p>
          <p>
            За този проблем известният медик разчита не само на теоретичните
            познания, но и на научните и практични постижения за адекватна
            подкрепа на черния дроб. Ето какво разказа той:
          </p>
          <p></p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h5>
            Здравейте, проф. Григоров! Защо черният дроб е толкова важен орган?
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Черният дроб е ключов орган в човешкия организъм, който хората
            нерядко подценяват, защото не винаги боли, за да му обърнем
            внимание. Сега той е на прицел и опазването му е жизнено важно.
          </p>
          <p>
            Ролята му в човешкия организъм е разнообразна и много отговорна.
            Когато организмът е подложен на вирусна атака и ние допълнително
            (основателно или не) го натоварим с антибиотици и други лекарства,
            настъпва истинско изпитание за черния дроб.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h5>Как да подпомогнем черния дроб в сегашната ситуация?</h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Черният дроб може да бъде значително подпомогнат в това трудно време
            на пандемията, както преди и по време на боледуването, така и в
            процеса на последващото възстановяване (т.нар. пост-Ковид синдром).
            Не може, а трябва! Обяснявам защо:
          </p>
          <p>
            Вирус в превод означава „отрова“. Ковид 19 и неговите мутанти
            атакуват директно чернодробната клетка - „отравят“ я, особено ако тя
            страда от хронично увреждане. Без антиотрова, сме загубени. Добавим
            ли и антибиотици и други токсични медикаменти, черният дроб попада в
            изключително тежка ситуация – започва да декомпенсира.
          </p>
        </Col>
        <Col md={5}>
          <Image className="ml-3 mb-3 float-right w-100" src={enzimi} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <p>
            Спасение обаче има и аз се радвам, че имаме работещо, ефективно
            решение – ползването на хепатопротектори. От тях с най-голяма
            детоксична ефективност, доказана от множество клинични проучвания,
            имат извлеците на „белия трън“. От него се извличат чрез специална
            технология основни съставки на растението: силимарин, силибинин
            (най-ценният), силибин. Почти в никой чернодробен бустер не е
            упоменато точното съдържание на силибинина, което принципно определя
            стойността на лекарството. Поради това, аз ползвам и препоръчвам
            само този продукт, в който процентът на силибинина е изрично
            посочен. Наличието му в оптимални количества – цели 100 mg - във
            френския <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link> е главна причина
            да работя с този продукт и да съблюдавам постигнатите резултати. А
            те са много добри.
          </p>
          <p>
            Производителят успешно е включил към екстракта и биоактивни съставки
            на артишок, глухарче, соево и ленено масло, като по този начин
            ХЕПАТОФЕЛИН изпълва всички изисквания на СЗО за чернодробна
            протекция.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h5>С какво друго ХЕПАТОФЕЛИН се отличава като високоефективен?</h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Той се предлага в маслен разтвор, затворен херметически в капсули.
            Това прави приема лесен, с висока усвояемост на съставките от
            организма. Нужни са само две капсули на ден за поне тримесечен
            период.
          </p>
          <p>
            И още. Съставките в него не са термично обработени, поради което
            запазват максималния си лечебен потенциал. Това е постигнато чрез
            технология, разработена от Capsugel, Франция. Специалистите, както и
            аз, се доверяваме само на клинично доказани и научно издържани
            продукти.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h5>За какво друго помага ХЕПАТОФЕЛИН?</h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link> подпомага процеса на
            клетъчна регенерация и поддържа чернодробните ензими, контролира
            обмяната на холестерола, стабилизира дейността на сърдечно-съдовата
            система и индиректно нормализира кръвната захар. Сами разбирате
            стойността му в ежедневната лечебна практика и най-вече за
            чернодробната функция, респ. патология: от обикновения мастен черен
            дроб до цирозата, превантивно по време на коронавирусните епидемии,
            максимално дозиран по време на острия и поддържащ през бавния,
            възстановителен (т.нар. дълъг Ковид) синдром.
          </p>
          <p>
            Без такава подкрепа рискуваме да оставим този важен орган трайно да
            се увреди или да се повиши степента на съществуващо вече поражение.
            Както се казва, да спазваме правилото: да лекуваме острия процес,
            като пазим стабилността на органите.
          </p>
        </Col>
      </Row>

      <Row className="product-container mb-4">
        <Col>
          <Link to="/hepatofelin">
            <Image className="w-100" src={hepatofelin} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default HepatofelinGrigorov;
